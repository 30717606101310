import React, { useState, useEffect, useCallback, useRef } from 'react';
import whatsappIcon from '../../assets/whatsappIcon.svg';
import loopVideo from '../../assets/video/hitch-landing.mp4';
import placeholderImage from '../../assets/placeholderImage.jpg';
import { handleMessageUsClick } from '../../utils/whatsapputils';
import { Link } from 'react-scroll';

const Introduction: React.FC = () => {
    const [videoSrc] = useState(loopVideo);
    const [videoError, setVideoError] = useState(false);
    const [loading, setLoading] = useState(true);
    const videoRef = useRef<HTMLVideoElement | null>(null);

    const handleVideoError = useCallback(() => {
        setVideoError(true);
    }, []);

    const handleLoadedData = useCallback(() => {
        setLoading(false);
    }, []);

    useEffect(() => {
        const videoElement = videoRef.current;
        if (videoElement) {
            videoElement.addEventListener('error', handleVideoError);
            videoElement.addEventListener('loadeddata', handleLoadedData);
        }

        return () => {
            if (videoElement) {
                videoElement.removeEventListener('error', handleVideoError);
                videoElement.removeEventListener('loadeddata', handleLoadedData);
            }
        };
    }, [handleVideoError, handleLoadedData]);

    return (
        <div className="w-full h-full flex justify-center bg-[#fffdfb]" >
            <div className="w-full max-w-screen-2xl flex flex-col md:flex-row px-2 md:pl-28">
                <div className="w-full md:w-3/5 h-auto px-2 flex flex-col justify-center items-start mt-24 md:mt-36 md:py-44">
                    <div className="w-full h-auto flex flex-col justify-center items-start gap-8">
                        <div className="self-stretch h-auto flex flex-col justify-start items-start gap-6">
                            <div className="w-full text-gray-900 md:text-6xl text-4xl font-semibold font-['Figtree'] leading-snug">
                                Dating online<br />
                                but <span className="underline" style={{ textDecorationColor: '#fe5f00', textDecorationThickness: '2px', textUnderlineOffset: '8px' }}>this time for real</span>
                            </div>
                            <div className="w-full text-gray-600 md:text-xl text-base font-normal font-['Figtree'] leading-loose">
                                Experience dating in a completely different way.<br /> We set the date and you just show up.
                            </div>
                        </div>
                        <div className="w-full flex flex-col md:flex-row justify-start items-start gap-4">
                            <Link to="contact-us" smooth={true} duration={500} className="w-full md:w-auto">
                                <button className="w-full md:w-auto flex justify-center md:justify-start items-center gap-4 bg-[#fe5f00] hover:bg-[#e55500] rounded-3xl shadow focus:outline-none cursor-pointer" style={{ height: '52px' }}>
                                    <div className="w-full flex justify-center items-center gap-2 px-6 py-0">
                                        <div className="text-white font-semibold font-['Figtree'] leading-normal" style={{ fontSize: '16px' }}>
                                            Request early access
                                        </div>
                                    </div>
                                </button>
                            </Link>
                            <button 
                                                        onClick={() => handleMessageUsClick()}
                            className="w-full md:w-auto flex justify-center md:justify-start items-center gap-2 px-6 py-0 rounded-3xl border-2 border-[#fe5f00]/25 hover:bg-[#fff7ef] focus:outline-none cursor-pointer" style={{ height: '52px' }}>
                                <img className="w-6 h-6" src={whatsappIcon} alt="Message us" />
                                <div className="text-[#0e1827] font-medium font-['Figtree'] leading-normal" style={{ fontSize: '16px' }}>
                                    Message us
                                </div>
                            </button>
                        </div>
                    </div>
                </div>
                <div className="w-full md:w-2/5 h-150 flex relative mt-[24px] md:mt-36 justify-end">
                    {videoError ? (
                        <img src={placeholderImage} alt="Video placeholder" className="absolute inset-0 w-auto h-4/5 object-contain md:ml-7" />
                    ) : (
                        <>
                            {loading && <img src={placeholderImage} alt="Loading placeholder" className="absolute w-auto h-full object-contain sm:ml-0 md:ml-8" />}
                            <video
                                ref={videoRef}
                                className="w-full h-full object-contain sm:ml-0 md:ml-7"
                                src={videoSrc}
                                autoPlay
                                loop
                                muted
                                playsInline
                                aria-label="Introduction video"
                            />
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Introduction;
