import React from 'react';
import Logo from '../../assets/headerLogo.svg';

const Header: React.FC = () => {
    return (
        <div className="w-full h-20 bg-white fixed top-0 left-0 right-0 flex z-50 justify-center">
            <div className="w-full max-w-screen-2xl flex justify-between items-center md:px-4 px-4">
                <img className="w-[98.5px] h-[32.54px]" src={Logo} alt="Header Logo" />
            </div>
        </div>
    );
};

export default Header;
