import React, { useState } from 'react';
import Alert from '../alerts/alert';

const Footer: React.FC = () => {
    const [isPrivacyModalOpen, setPrivacyModalOpen] = useState(false);
    const handlePrivacyClick = () => {
        setPrivacyModalOpen(true);
    };
    const closePrivacyModal = () => {
        setPrivacyModalOpen(false);
    };

    const [isTermsOpen, setTermsOpen] = useState(false);
    const handleTermsClick = () => {
        setTermsOpen(true);
    };
    const closeTerms = () => {
        setTermsOpen(false);
    };

    const [isCookiesOpen, setCookiesOpen] = useState(false);
    const handleCookiesClick = () => {
        setCookiesOpen(true);
    };
    const closeCookies = () => {
        setCookiesOpen(false);
    };

    return (
        <div className="w-full h-full flex justify-center" style={{ backgroundColor: '#0e1827' }}>
            <div className="w-full max-w-screen-2xl bg-[#0e1827] flex flex-col justify-start items-center gap-0 px-4">
                <div className="w-full flex flex-col justify-start items-center">
                    <div className="self-stretch flex flex-col justify-start items-center gap-0">
                        <div className="flex flex-col justify-start items-center gap-8">
                            <div className="w-full text-start text-white text-[28px] font-medium font-['Figtree'] leading-9 mt-[64px]">
                                <span className="block md:inline">Dating online</span>
                                <span className="block md:inline"> but this time for real</span>
                            </div>
                            <div className="w-full md:w-96 md:text-center text-white text-[16px] font-normal font-['Figtree'] leading-loose">
                                Experience dating in a completely different way. We set the date and you just show up.
                            </div>
                        </div>
                    </div>
                </div>
                <div className="w-full flex flex-col justify-start items-start gap-0 mt-[64px]"> 
                    <div className="w-full self-stretch py-8 border-t border-slate-600 flex flex-col md:flex-row justify-between items-start md:items-center gap-4">
                        <div className="text-gray-300 text-base font-normal font-['Figtree'] leading-normal">
                            hitch 2024
                        </div>
                        <div className="flex justify-start md:justify-end items-center gap-4">
                            <div onClick={handleTermsClick} className="text-gray-300 text-base font-semibold font-['Figtree'] leading-normal cursor-pointer">
                                Terms
                            </div>
                            <div onClick={handlePrivacyClick} className="text-gray-300 text-base font-semibold font-['Figtree'] leading-normal cursor-pointer">
                                Privacy
                            </div>
                            <div onClick={handleCookiesClick} className="text-gray-300 text-base font-semibold font-['Figtree'] leading-normal cursor-pointer">
                                Cookies
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {isPrivacyModalOpen && (
                <Alert
                    title="Privacy Policy"
                    content="This is place holder text. The basic dialog for modals should contain only valuable and relevant information. Simplify dialogs by removing unnecessary elements or content that does not support user tasks. If you find that the number of required elements for your design are making the dialog excessively large, then try a different design solution."
                    onClose={closePrivacyModal}
                />
            )}
            {isTermsOpen && (
                <Alert
                    title="Terms and Conditions"
                    content="This is place holder text. The basic dialog for modals should contain only valuable and relevant information. Simplify dialogs by removing unnecessary elements or content that does not support user tasks. If you find that the number of required elements for your design are making the dialog excessively large, then try a different design solution."
                    onClose={closeTerms}
                />
            )}
            {isCookiesOpen && (
                <Alert
                    title="Cookies Policy"
                    content="This is place holder text. The basic dialog for modals should contain only valuable and relevant information. Simplify dialogs by removing unnecessary elements or content that does not support user tasks. If you find that the number of required elements for your design are making the dialog excessively large, then try a different design solution."
                    onClose={closeCookies}
                />
            )}
        </div>
    );
};

export default Footer;
