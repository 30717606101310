import React, { useState, useEffect } from 'react';
import Alert from '../alerts/alert';
const loadSMTPJS = () => {
  const existingScript = document.getElementById('smtpjs-script');
  if (!existingScript) {
    const script = document.createElement('script');
    script.src = 'https://smtpjs.com/v3/smtp.js';
    script.id = 'smtpjs-script';
    script.async = true;
    document.body.appendChild(script);
  }
};

const ContactForm: React.FC = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [agreedToPolicy, setAgreedToPolicy] = useState(false);
  const [isPolicyOpen, setIsPolicyOpen] = useState(false);
  const [error, setError] = useState('');
  const [submissionError, setSubmissionError] = useState('');
  const [success, setSuccess] = useState('');

  useEffect(() => {
    loadSMTPJS();
  }, []);

  const handlePrivacyPolicyClick = () => {
    setIsPolicyOpen(true);
  };

  const handleClosePolicy = () => {
    setIsPolicyOpen(false);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!name || !email || !message || !agreedToPolicy) {
      setError('Please fill in all fields and agree to the privacy policy.');
      return;
    }

    setError('');
    setSubmissionError('');
    setSuccess('');

    try {
      const emailScript = document.getElementById('smtpjs-script') as HTMLScriptElement;
      if (emailScript) {
        (window as any).Email.send({
          SecureToken: '2ef5cde7-01fc-4f82-a5a4-0d34c290f1f0',
          To: 'hitch@hitch-hatch.com',
          From: 'hitch@hitch-hatch.com',
          Subject: `Hitch Hatch enquiry ${name}`,
          Body: `
            Name: ${name} <br/>
            Email: ${email} <br/>
            Message: ${message}
          `,
        }).then((response: string) => {
          if (response === 'OK') {
            setSuccess('Your message has been sent successfully.');
            setName('');
            setEmail('');
            setMessage('');
            setAgreedToPolicy(false);
          } else {
            setSubmissionError('Failed to send your message. Please try again.');
          }
        }).catch((error: any) => {
          console.error('Error sending email:', error);
          setSubmissionError('An error occurred while sending your message.');
        });
      } else {
        setSubmissionError('SMTP.js is not loaded. Please try again later.');
      }
    } catch (error) {
      console.error('Error:', error);
      setSubmissionError('Submission failed. Please try again later.');
    }
  };

  return (
    <div className="w-full h-full flex justify-center bg-white">
    <div id="contact-us" className="w-full max-w-screen-2xl md:flex-row  px-4 md:px-[120px]">
      <div className="w-full h-auto py-16 md:py-[96px] bg-white flex flex-col justify-start items-center gap-16">
        <div className="w-full h-32 px-4 flex flex-col justify-start items-center gap-8">
          <div className="w-full h-32 flex flex-col justify-start items-center gap-12">
            <div className="w-full h-32 flex flex-col justify-start items-center gap-5">
              <div className="w-full h-20 flex flex-col justify-start items-center gap-3">
                <div className="w-full text-center text-orange-600 text-base font-medium leading-normal">Contact us</div>
                <div className="w-full text-center text-gray-900 text-[38px] font-semibold leading-10">Check our app</div>
              </div>
              <div className="w-full text-center text-gray-600 text-[16px] font-normal leading-loose">Leave us your email and tell us why you would like to test it.</div>
            </div>
          </div>
        </div>
        {/* Form Section */}
        <form onSubmit={handleSubmit} className="w-full max-w-md md:max-w-lg lg:max-w-xl mx-auto flex flex-col justify-start items-start gap-16">
          <div className="w-full flex flex-col justify-start items-center gap-16">
            <div className="w-full flex flex-col justify-start items-start gap-8">
              <div className="w-full flex flex-col justify-start items-start gap-6">
                {error && <div className="w-full text-red-600 text-[13px] font-medium leading-tight" aria-live="polite">{error}</div>}
                {submissionError && <div className="w-full text-red-600 text-[13px] font-medium leading-tight" aria-live="polite">{submissionError}</div>}
                {success && <div className="w-full text-green-600 text-[13px] font-medium leading-tight" aria-live="polite">{success}</div>}
                <div className="w-full flex flex-col justify-start items-start gap-1.5">
                  <div className="text-gray-900 text-[13px] font-medium leading-tight">Name</div>
                  <input
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    placeholder="Your Name"
                    required
                    aria-describedby="name-error"
                    className="w-full h-11 px-5 py-2.5 bg-white rounded-[22px] border border-red-200"
                  />
                </div>
                <div className="w-full flex flex-col justify-start items-start gap-1.5">
                  <div className="text-gray-900 text-[13px] font-medium leading-tight">Email</div>
                  <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="you@mail.com"
                    required
                    aria-describedby="email-error"
                    className="w-full h-11 px-5 py-2.5 bg-white rounded-[22px] border border-red-200"
                  />
                </div>
                <div className="w-full flex flex-col justify-start items-start gap-1.5">
                  <div className="text-gray-900 text-[13px] font-medium leading-tight">Message</div>
                  <textarea
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    placeholder="Leave us a message..."
                    required
                    aria-describedby="message-error"
                    className="w-full px-5 py-4 bg-white rounded-3xl border border-red-200 text-gray-600 text-base font-normal leading-normal"
                  />
                </div>
                <div className="flex items-center gap-3">
                  <input
                    type="checkbox"
                    checked={agreedToPolicy}
                    onChange={(e) => setAgreedToPolicy(e.target.checked)}
                    id="privacy-policy"
                    className="w-5 h-5 bg-white rounded-md border border-gray-600"
                  />
                  <label htmlFor="privacy-policy" className="text-gray-600 text-base font-normal leading-normal">
                    You agree to our <span className="underline cursor-pointer" onClick={handlePrivacyPolicyClick}>privacy policy</span>.
                  </label>
                </div>
              </div>
              <button type="submit" className="w-full h-[52px] py-3 bg-[#fe5f00] hover:bg-[#e55500] rounded-3xl shadow text-white text-[16px] font-medium leading-normal">
                Send message
              </button>
            </div>
          </div>
        </form>
      </div>
      {isPolicyOpen && (
                <Alert
                    title="Privacy Policy"
                    content="This is place holder text. The basic dialog for modals should contain only valuable and relevant information. Simplify dialogs by removing unnecessary elements or content that does not support user tasks. If you find that the number of required elements for your design are making the dialog excessively large, then try a different design solution. This is place holder text. The basic dialog for modals should contain only valuable and relevant information. Simplify dialogs by removing unnecessary elements or content that does not support user tasks. If you find that the number of required elements for your design are making the dialog excessively large, then try a different design solution. This is place holder text. The basic dialog for modals should contain only valuable and relevant information. Simplify dialogs by removing unnecessary elements or content that does not support user tasks. If you find that the number of required elements for your design are making the dialog excessively large, then try a elements for your design are making the dialog excessively large, then try a elements for your design are making the dialog excessively large, then try a 
This is place holder text. The basic dialog for modals should contain only valuable and relevant information. Simplify dialogs by removing unnecessary elements or content that does not support user tasks. If you find that the number of required elements for your design are making the dialog excessively large, then try a different design solution. This is place holder text. The basic dialog for modals should contain only valuable and relevant information. Simplify dialogs by removing unnecessary elements or content that does not support user tasks. If you find that the number of required elements for your design are making the dialog excessively large, then try a different design solution. This is place holder text. The basic dialog for modals should contain only valuable and relevant information. Simplify dialogs by removing unnecessary elements or content that does not support user tasks. If you find that the number of required elements for your design are making the dialog excessively large, then try a elements for your design are making the dialog excessively large, then try a elements for your design are making the dialog excessively large, then try a "
                    onClose={handleClosePolicy}
                />
            )}
    </div>
    </div>
  );
};

export default ContactForm;
