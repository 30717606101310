
const defaultPhoneNumber = '+971568896369'; 
const defaultMessage = 'Hello, I am interested in testing the new dating experience!'; 

export const handleMessageUsClick = (
    phoneNumber: string = defaultPhoneNumber,
    message: string = defaultMessage
) => {
    const encodedMessage = encodeURIComponent(message);
    const url = `https://wa.me/${phoneNumber}?text=${encodedMessage}`;
    window.open(url, '_blank');
};
