import React from 'react';
import Lottie from 'lottie-react';
import bellIcon from '../../assets/bell.svg';
import heartCircle from '../../assets/heart-circle.svg';
import hearts from '../../assets/hearts.svg';
import targetIcon from '../../assets/target.svg';
import featuresAnimation1 from '../../assets/animations/featuresAnimation_1.json';
import featuresAnimation3 from '../../assets/animations/featureAnimation3.json';
import FeatureVideo2blue from '../../assets/video/featuresVideo2blue.mp4';

const Information: React.FC = () => {
  return (
    <div className="w-full h-full flex justify-center bg-white">
      <div className="w-full max-w-screen-2xl px-4 md:px-[112px] relative overflow-hidden">

        {/* Section 1 */}
        <div className="w-full flex flex-col justify-center items-center gap-8 md:gap-24 pt-[64px] md:pt-[96px]">
          <div className="w-full md:w-[70%] flex flex-col justify-center items-center">
            <div className="w-full text-left md:text-center flex flex-col gap-5">
              <div className="text-[#fe5f00] text-base font-medium font-['Figtree'] leading-snug">
                Features
              </div>
              <div className="text-gray-900 font-semibold font-['Figtree'] text-[38px] md:text-[38px] md:leading-10 leading-[1.2]">
                <span className="block md:inline">No swiping,</span>
                <span className="block md:inline"> just be there</span>
              </div>
              <div className="text-gray-600 font-normal font-['Figtree'] text-[16px] md:leading-7">
                <span className="inline md:block">
                  An app in which we take care of your well-being while dating.
                </span>
                <span className="inline md:block">
                  To make online contact simple, authentic, and in harmony with you.
                </span>
              </div>
            </div>
          </div>

          <div className="w-full flex flex-col md:flex-row justify-center items-center gap-8 md:gap-12">
            <div className="flex-[0_1_100%] md:flex-[0_1_50%] flex flex-col justify-start items-start gap-8 order-2 md:order-1">
              <div className="flex flex-col justify-start items-start gap-6">
                <div className="w-16 h-16 p-4 bg-white rounded-full border-2 border-[#fe5f00] flex justify-center items-center">
                  <img src={bellIcon} alt="bellIcon" className="w-7 h-7" />
                </div>
                <div className="flex flex-col justify-start items-start gap-4">
                  <div className="text-gray-900 font-medium font-['Figtree'] text-[28px] leading-7 md:text-3xl md:leading-9">
                    Look forward to a hitch in your area
                  </div>
                  <div className="text-gray-600 font-normal font-['Figtree'] text-[16px] md:leading-7">
                    We decide when you date. Based on your location, we invite you to an event for people from your area.
                  </div>
                </div>
              </div>
              <div className="flex flex-col justify-start items-start gap-5">
                {[
                  'To join your first hitch you need to tell us something about yourself',
                  'Wait for the nearest hitch in your area',
                  'Events take place regularly, several days apart',
                ].map((text, index) => (
                  <div key={index} className="flex items-start gap-3">
                    <img src={heartCircle} alt="circular heart icon" className="w-7 h-7" />
                    <div className="text-gray-600 font-normal font-['Figtree'] text-[16px] md:text-xl leading-[1.75]">
                      {text}
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="flex-[0_1_100%] md:flex-[0_1_50%] flex justify-center items-center order-2 md:order-1">
                    {/* <div className="relative md:absolute md:-right-1 w-80 md:w-[40%] h-80 md:h-96 rounded-full md:rounded-tl-full md:rounded-bl-full md:rounded-tr-none md:rounded-br-none border-2 border-[#fe5f00] flex justify-center items-center "> */}
                    <div className="relative md:absolute md:-right-1 w-80 md:w-[40%] h-80 md:h-96 rounded-full md:rounded-tl-full md:rounded-bl-full md:rounded-tr-none md:rounded-br-none flex justify-center items-center" style={{ background: 'linear-gradient(to bottom, #C4E6FF 0%, #F1F9FF 100%)' }}>
    <Lottie animationData={featuresAnimation1} loop={true}className="w-[80%] md:w-[70%] lg:w-[60%] xl:w-[50%] h-auto md:mb-[50px] " />
</div>

                    </div>
          </div>
        </div>

        {/* Section 2 */}
        <div className="w-full flex flex-col md:flex-row justify-center items-center gap-8  py-[64px] md:py-[96px]">
          <div className="flex-[0_1_100%] md:flex-[0_1_50%] flex justify-center items-center order-2 md:order-1">
            <video className=" h-[30%] absolute -left-1 mt-12 object-contain" src={FeatureVideo2blue} autoPlay loop muted playsInline />
          </div>
          <div className="flex-[0_1_100%] md:flex-[0_1_50%] flex flex-col justify-start items-start gap-8 order-1 md:order-2">
            <div className="flex flex-col justify-start items-start gap-6">
              <div className="w-16 h-16 p-4 bg-white rounded-full border-2 border-[#fe5f00] flex justify-center items-center">
                <img src={hearts} alt="heart icon" className="w-7 h-7" />
              </div>
              <div className="text-gray-900 font-medium font-['Figtree'] text-[28px] leading-7 md:text-3xl md:leading-9">
                Five video dates at a time
              </div>
              <div className="text-gray-600 font-normal font-['Figtree'] text-[16px] md:leading-7">
                Each hitch means as many as five dates with people specially selected based on your preferences.
              </div>
            </div>
            <div className="flex flex-col justify-start items-start gap-5">
              {[
                'There are five 2-minute dates per event',
                'After the date, you decide whether you will give this person a chance',
              ].map((text, index) => (
                <div key={index} className="flex items-start gap-3">
                  <img src={heartCircle} alt="heart bullet icon" className="w-7 h-7" />
                  <div className="text-gray-600 font-normal font-['Figtree'] text-[16px] md:text-xl leading-[1.75]">
                    {text}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

            {/* Section 3 */}
            <div className="w-full flex flex-col md:flex-row justify-center md:my-[30px] items-center gap-8 md:gap-12">
            <div className="flex-[0_1_100%] md:flex-[0_1_50%] flex flex-col justify-start items-start gap-8 order-2 md:order-1">
              <div className="flex flex-col justify-start items-start gap-6">
                <div className="w-16 h-16 p-4 bg-white rounded-full border-2 border-[#fe5f00] flex justify-center items-center">
                  <img src={targetIcon} alt="bellIcon" className="w-7 h-7" />
                </div>
                <div className="flex flex-col justify-start items-start gap-4">
                  <div className="text-gray-900 font-medium font-['Figtree'] text-[28px] leading-7 md:text-3xl md:leading-9">
                  Have a match? You can call or type
                  </div>
                  <div className="text-gray-600 font-normal font-['Figtree'] text-[16px] md:leading-7">
                  If you've been paired with another person, you can chat with them or meet them on another video.
                  </div>
                </div>
              </div>
              <div className="flex flex-col justify-start items-start gap-5 ">
                {[
                'Want to chat later? Go ahead and join the next hitch',
                'A hitch is a social event during which you can talk to other users via video chat',
              ].map((text, index) => (
                  <div key={index} className="flex items-start gap-3">
                    <img src={heartCircle} alt="circular heart icon" className="w-7 h-7" />
                    <div className="text-gray-600 font-normal font-['Figtree'] text-[16px] md:text-xl leading-[1.75]">
                      {text}
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="flex-[0_1_100%] md:flex-[0_1_50%] flex justify-center items-center order-2 md:order-1">
                    {/* <div className="relative md:absolute md:-right-1 w-80 md:w-[40%] h-80 md:h-96 rounded-full md:rounded-tl-full md:rounded-bl-full md:rounded-tr-none md:rounded-br-none border-2 border-[#fe5f00] flex justify-center items-center "> */}
                    <div className="relative md:absolute md:-right-1 w-80 md:w-[40%] h-80 md:h-96 rounded-full md:rounded-tl-full md:rounded-bl-full md:rounded-tr-none md:rounded-br-none flex justify-center items-center" style={{ background: 'linear-gradient(to bottom, #C4E6FF 0%, #F1F9FF 100%)' }}>
    <Lottie animationData={featuresAnimation3} loop={true}className="w-[80%] md:w-[70%] lg:w-[60%] xl:w-[50%] h-auto md:mb-[50px] " />
</div>

                    </div>
          </div>
        </div>
        </div>
    );
};

export default Information;
