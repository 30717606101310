import React from 'react';
import Introduction from '../../components/Introduction/Introduction';
import Information from '../../components/Information/Information';
import Experience from '../../components/Experience/Experience';
import Values from '../../components/Values/Values';
import Security from '../../components/Security/Security';
import ContactForm from '../../components/Contact-Us/ContactForm';

const Home: React.FC = () => {
  return (
    <div className='w-full'>
<Introduction/>
<Information/>
<Experience/>
<Values/>
<Security/>
<ContactForm/>
    </div>
  );
};

export default Home;
