import React, { useState, useEffect } from 'react';

type AlertProps = {
    title: string;
    content: string;
    onClose: () => void;
};

const Alert: React.FC<AlertProps> = ({ title, content, onClose }) => {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        setIsVisible(true);
    }, []);

    return (
        <div className={`fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 z-50 transition-opacity duration-300 ${isVisible ? 'opacity-100' : 'opacity-0'} `}>
            <div className={`w-[100%] h-[95%] md:w-[640px] md:h-[766px] bg-white p-6 rounded-[32px] shadow-lg flex flex-col m-4 transition-transform duration-500 ${isVisible ? 'translate-y-0' : 'translate-y-10'} `}>
                <h2 className="text-lg font-semibold text-gray-800 mb-4">{title}</h2>
                <div className="flex-1 overflow-y-auto text-gray-600 text-sm font-normal font-['Figtree'] leading-tight scrollbar-thin scrollbar-thumb-orange-600 scrollbar-track-orange-100">
                    {content}
                </div>
                <div className="flex justify-end">
                    <div
                        className="w-[75px] h-8 flex justify-center items-center gap-2.5 cursor-pointer"
                        onClick={onClose}
                    >
                        <div className="text-slate-600 text-base font-semibold font-['Figtree'] leading-loose">Cancel</div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Alert;
