import React from 'react';
import whatsappIcon from '../../assets/wpIcon-white.svg';
import { handleMessageUsClick } from '../../utils/whatsapputils';
import { Link } from 'react-scroll';

const Experience: React.FC = () => {
    return (
        <div className="w-full h-full flex justify-center bg-[#fffdfb]">
            <div className="w-full max-w-screen-2xl h-auto py-[64px] md:pt-[96px] px-6 md:px-24 lg:px-[112px] flex-col justify-center items-center inline-flex">
                <div className="w-full flex-col justify-center items-start gap-6 md:gap-8 flex">
                    <div className="px-4 pt-10 pb-6 md:px-20 md:py-16 bg-[#0e1827] rounded-[32px] md:rounded-[225px] flex flex-col md:flex-row items-start gap-[32px] justify-center">
                        <div className="w-full md:w-1/2 flex-col justify-start items-start gap-4 flex">
                            <h2 className="text-white font-semibold font-['Figtree'] leading-8 text-[28px]">
                                Test the new dating experience
                            </h2>
                            <p className="text-white text-base md:text-xl font-normal font-['Figtree'] leading-6 md:leading-7">
                                As soon as we finish developing the app, we'll be happy to give you a chance to test it.
                            </p>
                        </div>
                        <div className="w-full md:w-1/2 flex flex-col md:flex-row justify-center items-center gap-4 mt-6 md:mt-0">
                            <Link to="contact-us" smooth={true} duration={500} className="w-full md:hidden">
                                <button className="w-full h-[52px] px-4 py-2 md:px-5 md:py-3 bg-[#fe5f00] hover:bg-[#e55500] rounded-[244px] shadow flex justify-center items-center gap-2 text-[16px]">
                                    <span className="text-white font-medium font-['Figtree'] leading-normal text-center">
                                        Request early access
                                    </span>
                                </button>
                            </Link>
                            <button 
                                                        onClick={() => handleMessageUsClick()}
                                                        className="w-full h-[52px] px-4 py-2 md:px-5 md:py-3 rounded-[244px] border-2 bg-[#0e1827] hover:bg-[#000000] border-white flex justify-center items-center gap-2 text-[16px]"
                            >
                                <img className="w-5 h-5 md:w-6 md:h-6" src={whatsappIcon} alt="Message Us"/>
                                <span className="text-white font-medium font-['Figtree'] leading-normal">Message us</span>
                            </button>
                            <Link to="contact-us" smooth={true} duration={500} className="w-full hidden md:block">
                                <button className="w-full h-[52px] px-4 py-2 md:px-5 md:py-3 bg-[#fe5f00] hover:bg-[#e55500] rounded-[244px] shadow flex justify-center items-center gap-2 text-[16px]">
                                    <span className="text-white font-medium font-['Figtree'] leading-normal text-center">
                                        Request early access
                                    </span>
                                </button>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Experience;
