import React from 'react';
import Lottie from 'lottie-react';
import calendar from '../../assets/calendar.svg';
import clock from '../../assets/clock.svg';
import users from '../../assets/users-right.svg';
import messageSmile from '../../assets/message-smile-circle.svg';
import valuesAnimation from '../../assets/animations/valuesAnimation.json';

const valuesContent = [
  { icon: calendar, title: "Events made by us", description: "We decide when you date, so you spend only as much time in the app as necessary." },
  { icon: clock, title: "Contact in the same time", description: "Live video contact makes you focus on each other here and now. Without missing out." },
  { icon: users, title: "Forget the swipes", description: "We try to ensure that online contact remains human. You only see the people we have selected for you." },
  { icon: messageSmile, title: "Continuous development", description: "No match? We can help you and guide you on how to better express your thoughts and emotions." },
];

const Values: React.FC = () => {
  return (
    <div className="w-full h-full flex justify-center bg-[#fffdfb]">
    <div className="w-full max-w-screen-2xl flex flex-col md:flex-row px-4 md:px-32">
      <div className="w-full md:w-1/2 flex flex-col justify-start items-start space-y-[64px]  ">
        <div className="flex flex-col justify-start items-start space-y-5">
          <div className="flex flex-col justify-start items-start space-y-3">
            <div className="text-[#fe5f00] text-base font-medium">Values</div>
<div className="text-gray-900 font-semibold leading-10" style={{ fontSize: '38px' }}>
    <span className="block md:inline">We care about</span>
    <span className="block md:inline"> your well-being</span>
</div>
          </div>
          <div className="text-gray-600 font-normal" style={{ fontSize: '16px' }}>
    A new quality of dating that takes care of you and your emotions.
</div>
        </div>
        
        <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
          {valuesContent.map((item, index) => (
            <div key={index} className="flex flex-col justify-start items-start space-y-5">
              <div className="w-12 h-12 p-3 bg-white rounded-3xl border-2 border-[#fe5f00] flex justify-center items-center">
                <img src={item.icon} alt={item.title} className="w-6 h-6" />
              </div>
              <div className="flex flex-col justify-start items-start space-y-2">
              <div>
    <div className="text-gray-900 font-medium leading-loose" style={{ fontSize: '21px' }}>
        {item.title}
    </div>
    <div className="text-gray-600 font-normal leading-normal" style={{ fontSize: '16px' }}>
        {item.description}
    </div>
</div>

              </div>
            </div>
          ))}
        </div>
      </div>
      
      <div className="w-full md:w-[40%] flex justify-center md:justify-center items-start mt-[48px] md:mt-8 md:ml-[100px]">
        <Lottie animationData={valuesAnimation} loop={true} className="w-full h-auto" />
      </div>
    </div>
    </div>
  );
};

export default Values;
