import React from 'react';
import './Layout.scss'; // Ensure to import your CSS file

import Header from '../Header/Header';
import Footer from '../Footer/Footer';

type LayoutProps = {
  children: React.ReactNode;
};

const Layout: React.FC<LayoutProps> = ({ children }) => {
  return (
    <div className="flex flex-col min-h-screen">
      <Header />
        {children}
      <Footer />
    </div>
  );
};

export default Layout;
