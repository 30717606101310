import React from 'react';
import login from '../../assets/login.svg';
import report from '../../assets/report.svg';
import review from '../../assets/review.svg';

const Security: React.FC = () => {
    return (
        <div className="w-full h-full flex justify-center bg-white ">
        <div className="w-full max-w-screen-2xl md:flex-row  px-1 pt-[64px] md:pt-[96px] md:px-[112px] ">
            <div className="w-full pt-[64px] pb-[24px] md:p-[96px] bg-gradient-to-t from-sky-50 to-sky-200 rounded-tl-[112px] md:rounded-tl-[244px] rounded-tr-[112px] md:rounded-tr-[244px] flex-col justify-start items-center md:gap-16 inline-flex ">
                <div className="px-8 flex-col justify-start items-start flex">
                    <div className="flex-col justify-start items-center flex">
                        <div className="flex-col gap-3 flex">
                            <div className="text-center text-orange-600 text-base font-medium font-['Figtree']">
                                Security
                            </div>
                            <div className="text-center text-gray-900 font-semibold font-['Figtree']" style={{ fontSize: '38px' }}>
                                <span className="block md:inline">We want you</span>
                                <span className="block md:inline"> to feel safe</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="px-8 py-8 md:px-0 md:py-0 flex flex-col md:flex-row justify-center items-center gap-10 md:gap-8">
    <div className="flex flex-col md:flex-row justify-start items-center gap-10 md:gap-8">
        <div className="flex flex-col py-4 justify-start items-center gap-2">
            <div className="w-12 h-12 p-3 rounded-3xl border-2 border-orange-600 flex justify-center items-center">
                <img src={login} alt='login' className="w-6 h-6" />
            </div>
            <div className="flex flex-col justify-center items-center gap-1">
                <div className="text-center text-gray-900 py-2" style={{ fontSize: '21px' }}>
                    Leave any time you want
                </div>
                <div className="text-center text-gray-600" style={{ fontSize: '16px' }}>
                    You can leave the event at any time during dating and join at another opportunity.
                </div>
            </div>
        </div>
        <div className="flex flex-col py-4 justify-start items-center gap-2">
            <div className="w-12 h-12 p-3 rounded-3xl border-2 border-orange-600 flex justify-center items-center">
                <img src={report} alt='report' className="w-6 h-6" />
            </div>
            <div className="flex flex-col justify-center items-center gap-1">
                <div className="text-center text-gray-900 py-2" style={{ fontSize: '21px' }}>
                    Report
                </div>
                <div className="text-center text-gray-600" style={{ fontSize: '16px' }}>
                    If some behavior violates your boundaries, you can report it and we will look into it.
                </div>
            </div>
        </div>
        <div className="flex flex-col py-4 justify-start items-center gap-2">
            <div className="w-12 h-12 p-3 rounded-full border-2 border-orange-600 flex justify-center items-center">
                <img src={review} alt='review' className="w-6 h-6" />
            </div>
            <div className="flex flex-col justify-center items-center gap-1">
                <div className="text-center text-gray-900 py-2" style={{ fontSize: '21px' }}>
                    Review other daters
                </div>
                <div className="text-center text-gray-600" style={{ fontSize: '16px' }}>
                    After each date, you can leave other people a little feedback, both positive and negative.
                </div>
            </div>
        </div>
    </div>
</div>

            </div>
        </div>
        </div>
    );
};

export default Security;
